import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import './Header.css';
import { FormattedMessage } from 'react-intl';
import LanguageSwitcher from '../LanguageSwitcher';
import Dropdown from 'react-multilevel-dropdown';

class Header extends React.Component {
  render() {
    return (
      <header className="Header">
      {/* <div className="Header-top">
          <LanguageSwitcher />
        </div> */}
        <div className="Header-container">
          <Logo />
          <nav className="Header-navbar">
            <ul className="Header-list">
            <li className='Header-item'>
                <Dropdown
                  buttonClassName='Header-link Header-link-dropdown'
                  title='La prueba'>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/descripcion'>
                      Descripción
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/modalidades'>
                      Modalidades
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/tarifas'>
                      Tarifas
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/reglamento'>
                      Reglamento
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/recorrido'>
                      El recorrido
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/seguro-devolucion'>
                      Seguro de devolución
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className='dropdown-item'>
                    <Link className='Header-link' to='/preguntas-frecuentes'>
                      Preguntas frecuentes
                    </Link>
                  </Dropdown.Item>
                </Dropdown>
              </li>
              <li className="Header-item">
                <Dropdown
                  buttonClassName="Header-link Header-link-dropdown"
                  title="Multimedia"
                >
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/fotos">
                      Fotos
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/videos">
                      Vídeos
                    </Link>
                  </Dropdown.Item>
                </Dropdown>
              </li>
              <li className="Header-item">
                <Link className="Header-link" to="/alojamiento">
                  Alojamiento
                </Link>
              </li>
              <li className="Header-item">     
                <Dropdown buttonClassName='Header-link Header-link-dropdown' title='Nuestras pruebas'>
                  <Dropdown.Item>
                    Pruebas Global
                    <Dropdown.Submenu>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="http://www.pontevedra4picos.com/"  rel="noopener noreferrer">Pontevedra 4 Picos</a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="http://www.epicracepontevedra.com/"  rel="noopener noreferrer">Epic Race Pontevedra</a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="http://www.costaatlanticamtbtour.com/"  rel="noopener noreferrer">Costa Atlántica MTB Tour</a>
                      </Dropdown.Item>
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    Pruebas amigas
                    <Dropdown.Submenu>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="http://www.algarvebikechallenge.com/es/"  rel="noopener noreferrer">Algarve Bike Challenge</a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="https://mountainquest.pt/"  rel="noopener noreferrer">Mountain Quest</a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="https://lalinbikerace.es/"  rel="noopener noreferrer">Lalín Bike Race</a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="https://www.amigosdamontanha.com/_maratona_btt_5_cumes_170"  rel="noopener noreferrer">Maratona BTT 5 Cumes</a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a className="dropdown-item" href="https://www.gigantedepiedra.com/"  rel="noopener noreferrer">Gigante de Piedra</a>
                      </Dropdown.Item>
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                </Dropdown>
              </li>
              {/* <li className="Header-item">
                <Dropdown buttonClassName='Header-link Header-link-dropdown' title='Ropa'>
                  <Dropdown.Item>
                    <Link className="dropdown-item" to="/ropa">
                      Ropa evento
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <a className="dropdown-item" href="https://eventos.emesports.es/inscripcion/tienda-global-copia/inscripcion_datos/"  rel="noopener noreferrer">Tienda Global DXT</a>
                  </Dropdown.Item>
                </Dropdown>
              </li> */}
              <li className="Header-item">
                <Link className="Header-link" to="/sponsors">
                  Sponsors
                </Link>
              </li>
              <li className="Header-item Header-item-inscription">
                <a className="Header-link"  rel="noopener noreferrer" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024">
                 <FormattedMessage id="header.enroll" defaultMessage="Inscríbete" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
