import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Alda from './alda.png';
import 'react-html5video/dist/styles.css';
import './HostingPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class HostingPage extends React.Component {
  render() {
    return (
      <div className="Hosting">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Hosting-hero"
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Hosting-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
APERTURA DE INSCRIPCIONES 1 AGOSTO
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Hosting-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Hosting-title">Alojamiento</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-8 offset-2">
                <article>
                  {/* <h4>
                    <FormattedMessage id="Hosting.title" defaultMessage="Hotel Alda Pontevedra" />
                  </h4> */}
                  <img src={Alda} width="300" alt="hotel"/>
                  <p className="my-4">Información: <a className="Hosting-web" href="https://www.aldahotels.es/alojamientos/hotel-alda-estacion-pontevedra/">Hotel Alda Pontevedra</a></p>
                </article>
              </div>
            </div>
{/* 
            <div className="container">
              <div className="row py-4">
                <h4 className="py-5 w-100 text-center">
                  <FormattedMessage id="wear.message" defaultMessage="Próximamente" />
                </h4>
              </div>
            </div> */}
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://eventos.emesports.es/inscripcion/pontevedra-4picos-bike-2020/inscripcion_datos/?iframe=1&lang=es&background=transparent"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default HostingPage;
