import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import './RatesPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';
import { Link } from 'react-router-dom';

class RatesPage extends React.Component {
  render() {
    return (
      <div className="Rates">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Rates-hero" 
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Rates-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage id="rates.banner" defaultMessage="APERTURA DE INSCRIPCIONES 1 AGOSTO" />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Rates-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                  <h3 className="Rates-title">
                    <FormattedMessage id="rates.title" defaultMessage="Tarifas" />
                  </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Rates-info-article">
                  <p className="Rates-info-text text-justify mt-4">
                    Las inscripciones se abrirán en la página web desde el Jueves 1 de agosto de 2024 hasta el domingo 15 de septiembre (con opción a camiseta conmemorativa del evento), o el domingo 29 de septiembre (sin opción a camiseta conmemorativa) o hasta completar aforo máximo de 1000 participantes.
                  </p>
                  <table className="table table-responsive">
                    <tbody>
                      <tr>
                        <th className="scope">
                          <FormattedMessage id="rates.first.step" defaultMessage="Del 1 al 4 de agosto (con camiseta)" />
                        </th>
                        <td>37 € iva incluido</td>
                      </tr>
                      <tr>
                        <th className="scope">
                          <FormattedMessage id="rates.second.step" defaultMessage="Del 5 de agosto al 15 de septiembre ( con camiseta)" />
                        </th>
                        <td>45 € iva incluido</td>
                      </tr>
                      <tr>
                        <th className="scope">
                          <FormattedMessage id="rates.third.step" defaultMessage="Del 16 al 29 de septiembre ( sin camiseta)" />
                        </th>
                        <td>45 € iva incluido</td>
                      </tr>
                    </tbody>
                  </table>
                  <p className='small'>
                    ***la tarifa se incrementa 3.5€ en caso de elegir la opción
                    de{' '}
                    <Link to='/seguro-devolucion'>“SEGURO DE DEVOLUCIÓN”</Link>{' '}
                    opcional
                  </p>
                  <p className='small'>
                    ***la tarifa se incrementa <u>35 euros</u> en caso de querer
                    el <u>maillot o chaleco</u> conmemorativo y <u>55 euros</u>{' '}
                    en el caso del <u>culotte negro</u> o 59 euros con <u>culotte</u> verde.
                  </p>
                  <p className='Rates-info-text text-justify'>
                    <strong>
                      Todo participante inscrito para poder obtener la devolución de su inscripción deberá marcar en la ficha de inscripción la opción “SEGURO DE DEVOLUCIÓN”. Aquellos participantes que no se acojan a este seguro, no tendrán derecho a la devolución de su inscripción en ninguna de las circunstancias.
                    </strong>
                  </p>
                  {/* <div className="Rates-inscription-button-wrapper">
                    <a className="Rates-inscription-button" href="#"  rel="noopener noreferrer">
                      <FormattedMessage id="rates.enroll" defaultMessage="Inscríbete" />
                    </a>
                  </div> */}
                </article>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="Rates-info-img"
                    style={{
                      backgroundImage: `url(${Image2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center',
                      backgroundSize: 'cover',
                      height: '800px',
                      marginRight: '-15px',
                      marginLeft: '-15px'
                    }}>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default RatesPage;
