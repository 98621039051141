import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import './ContactPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class ContactPage extends React.Component {
  render() {
    return (
      <div className="Contact">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Contact-hero"
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Contact-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage id="contact.banner" defaultMessage="APERTURA DE INSCRIPCIONES 1 AGOSTO" />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Contact-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Contact-title">
                  <FormattedMessage id="contact.title" defaultMessage="Contacto" />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 offset-lg-3">
                <article className="Contact-info-article">
                  <h4 className="Contact-info-title">
                    <FormattedMessage id="contact.info.title" defaultMessage="Puedes ponerte en contacto con nosotros rellenando este formulario" />
                  </h4>
                </article>
                <form className="Contact-form">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">
                      <FormattedMessage id="contact.email" defaultMessage="Correo electrónico" />
                    </label>
                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlInput1">
                      <FormattedMessage id="contact.name" defaultMessage="Nombre" />
                    </label>
                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlInput1">
                      <FormattedMessage id="contact.message" defaultMessage="Mensaje" />
                    </label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                  <div className="form-group">
                    <div className="Contact-button-wrapper">
                      <a className="Contact-button" href="mailto:contacto@globaldxt.com"  rel="noopener noreferrer">
                        <FormattedMessage id="home.send" defaultMessage="Enviar" />
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default ContactPage;
