import image1  from "./images/2019_4G8A5817.jpg";
import image2  from "./images/2019_4G8A5853.jpg";
import image3  from "./images/2019_4G8A5862.jpg";
import image4  from "./images/2019_4G8A5903.jpg";
import image5  from "./images/2019_4G8A5904.jpg";
import image6  from "./images/2019_4G8A5912.jpg";
import image7  from "./images/2019_4G8A5919.jpg";
import image8  from "./images/2019_4G8A5928.jpg";
import image9  from "./images/2019_4G8A5930.jpg";
import image10 from "./images/2019_4G8A5934.jpg";
import image11 from "./images/2019_4G8A5942.jpg";
import image12 from "./images/2019_4G8A5971.jpg";
import image13 from "./images/2019_4G8A5983.jpg";
import image14 from "./images/2019_4G8A6007.jpg";
import image15 from "./images/2019_4G8A6008.jpg";
import image16 from "./images/2019_4G8A6009.jpg";
import image17 from "./images/2019_4G8A6265.jpg";
import image18 from "./images/2019_4G8A6283.jpg";
import image19 from "./images/2019_4G8A6344.jpg";
import image20 from "./images/2019_4G8A6369.jpg";
import image21 from "./images/2019_IMG_4324.jpg";
import image22 from "./images/2019_IMG_4326.jpg";
import image23 from "./images/2019_IMG_4328.jpg";
import image24 from "./images/2019_IMG_4374.jpg";
import image25 from "./images/2019_IMG_4375.jpg";
import image26 from "./images/2019_IMG_4413.jpg";
import image27 from "./images/2019_IMG_4452.jpg";
import image28 from "./images/2019_IMG_4472.jpg";
import image29 from "./images/2019_IMG_4572.jpg";
import image30 from "./images/2019_IMG_5126.jpg";
import image31 from "./images/2019_IMG_7466.jpg";
import image32 from "./images/2019_IMG_7532.jpg";
import image33 from "./images/2019_IMG_7543.jpg";
import image34 from "./images/2019_IMG_7642.jpg";
import image35 from "./images/2019_IMG_8495.JPG";

const images2019 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image18, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
  { src: image24, width: 830, height: 645 },
  { src: image25, width: 830, height: 645 },
  { src: image26, width: 830, height: 645 },
  { src: image27, width: 830, height: 645 },
  { src: image28, width: 830, height: 645 },
  { src: image29, width: 830, height: 645 },
  { src: image30, width: 830, height: 645 },
  { src: image31, width: 830, height: 645 },
  { src: image32, width: 830, height: 645 },
  { src: image33, width: 830, height: 645 },
  { src: image34, width: 830, height: 645 },
  { src: image35, width: 830, height: 645 },
];

export default images2019;
