import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import maillot from './maillot.jpg';
import chaleco from './chaleco.jpg';
import culotte from './culotte.jpg';
import './WearPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class WearPage extends React.Component {
  render() {
    return (
      <div className="Wear">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Wear-hero" 
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Wear-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage id="Wear.banner" defaultMessage="APERTURA DE INSCRIPCIONES 1 AGOSTO" />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Wear-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                  <h3 className="Wear-title">
                    <FormattedMessage id="Wear.title" defaultMessage="Ropa Evento" />
                  </h3>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row py-4">
              <h4 className="">Consigue tu maiot a un precio especial. Lo recogerás el día de la prueba.</h4>
              <h4 className=""><strong>El chaleco está incluído con la inscripción.</strong></h4>
            </div>
            <div className="row py-4">
              <div className="col-12 col-lg-6">
                <img src={chaleco} alt="" className="img-fluid"/>
              </div>
              <div className="col-12 col-lg-6">
                <img src={maillot} alt="" className="img-fluid"/>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-12 col-lg-6">
                <img src={culotte} alt="" className="img-fluid"/>
              </div>
              {/* <div className="col-12 col-lg-6">
                <img src={Chaleco2} alt="" className="img-fluid"/>
              </div> */}
            </div>
            <div className="row py-4">
            {/* <div className="Wear-button-wrapper">
              <a className="Wear-button" href={Medidas}  rel="noopener noreferrer">Medidas</a>
            </div> */}
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default WearPage;
