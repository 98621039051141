import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.png";
import "react-html5video/dist/styles.css";
import "./ModalidadesPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

class ModalidadesPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Embassador-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></section>
        <section className="Embassador-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage
                    id="page.banner"
                    defaultMessage="APERTURA DE INSCRIPCIONES 1 AGOSTO"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Embassador-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Embassador-title">Modalidades</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-8 offset-2">
                <article className="text-justify">
                  <p>
                    Se establecen diferentes modalidades con el afán de generar la máxima diversión en los tramos cronometrados. Solo en estos tramos las modalidades serán efectivas sin cobrar importancia en el resto del recorrido.
                  </p>
                  <ul>
                    <li>
                      <strong>ÉLITE MASCULINO:</strong> aquellos participantes que son poseedores de licencia federativa élite.
                    </li>
                    <li>
                      <strong>ÉLITE FEMENINO:</strong> aquellas participantes que son poseedoras de la licencia federativa élite.

                    </li>
                    <li>
                      <strong>MASTER 30 MASCULINO:</strong> aquellos participantes que son poseedores de la licencia federativa master 30. En caso de acogerse a la licencia de día, aquellos participantes que tienen menos de 30 años el día en el que se celebra la prueba.
                    </li>
                    <li>
                      <strong>MASTER 40 MASCULINO:</strong> aquellos participantes que son poseedores de la licencia federativa master 40. En caso de acogerse a la licencia de día, aquellos participantes que tienen entre los 40 y 50 años el día en el que se celebra la prueba.
                    </li>
                    <li>
                      <strong>MASTER 50 MASCULINO:</strong> aquellos participantes que son poseedores de la licencia federativa master 50. En caso de acogerse a la licencia de día, aquellos participantes que tienen entre 50 y 60 años el día en el que se celebra la prueba.
                    </li>
                    <li>
                      <strong>GRANMASTER MASCULINO:</strong> aquellos participantes que tienes 60 o más años en día en el que se celebra la prueba.
                    </li>
                    <li>
                      <strong>MASTER FEMENINO:</strong> aquellas participantes con cualquier licencia master. En caso de acogerse a la licencia de día, las participantes de cualquier edad.
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"
                  
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default ModalidadesPage;
