import React from "react";
import Header from "../_components/Header";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "./VideosPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

import * as Tabs from "@radix-ui/react-tabs";
import Button from "../_components/Button";
const VideosPage = () => {
  return (
    <div className="Media">
      <Media query={{ minWidth: 768 }}>
        {(matches) => (matches ? <Header /> : <HeaderMobile />)}
      </Media>
      <section
        className="Media-hero"
        style={{
          height: "350px",
          width: "100%",
          backgroundImage: `url(${ImageHero})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      ></section>
      <section className="Media-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p>
                <FormattedMessage
                  id="rules.banner"
                  defaultMessage="APERTURA DE INSCRIPCIONES 1 AGOSTO"
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="Media-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3 className="Media-title">Vídeos</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="m-5">
        <Tabs.Root defaultValue="2019" orientation="vertical">
          <Tabs.List className="tab-header-list" aria-label="tabs photos">
            <Tabs.Trigger className="tab-header-item" value="2019">
              2019
            </Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2021">
              2021
            </Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2023">
              2023
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content className="tab-content-wrapper" value="2019">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Gvg2_ePt-RE"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/sgmeshJrvLs"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
          <Tabs.Content className="tab-content-wrapper" value="2021">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/WlENMIdsL7M"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/RZ7vQoqHSxg"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
          <Tabs.Content className="tab-content-wrapper" value="2023">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Z3wwuMWbcCY"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/f5JPMpi9Uf0?si=YcNjl9z1LpTSEVGM"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </section>
      <Footer />
      <Media query={{ minWidth: 768 }}>
        {(matches) =>
          matches ? (
            ""
          ) : (
            <section className="inscription-fixed-bar">
              <Button
                className="inscription-fixed"
                href="https://eventos.emesports.es/inscripcion/pontevedra-4-picos-bike-desafio-rias-baixas-2023/"
                
                rel="noopener noreferrer"
              >
                Inscríbete
              </Button>
            </section>
          )
        }
      </Media>
    </div>
  );
};

export default VideosPage;
