import image1 from "./images/2021_4G8A2194.jpg";
import image2 from "./images/2021_4G8A2214.jpg";
import image3 from "./images/2021_4G8A2226.jpg";
import image4 from "./images/2021_4G8A2252.jpg";
import image5 from "./images/2021_4G8A2261.jpg";
import image6 from "./images/2021_4G8A2264.jpg";
import image7 from "./images/2021_4G8A2271.jpg";
import image8 from "./images/2021_4G8A2315.jpg";
import image9 from "./images/2021_4G8A2316.jpg";
import image10 from "./images/2021_4G8A2322.jpg";
import image11 from "./images/2021_4G8A2324.jpg";
import image12 from "./images/2021_4G8A2413.jpg";
import image13 from "./images/2021_4G8A2443.jpg";
import image14 from "./images/2021_4G8A2444.jpg";
import image15 from "./images/2021_4G8A2445.jpg";
import image16 from "./images/2021_4G8A2446.jpg";
import image17 from "./images/2021_4G8A2447.jpg";
import image19 from "./images/2021_4G8A2460.jpg";
import image20 from "./images/2021_4G8A2465.jpg";
import image21 from "./images/2021_4G8A2488.jpg";
import image22 from "./images/2021_4G8A2510_1.jpg";
import image23 from "./images/2021_4G8A2556.jpg";
import image24 from "./images/2021_4G8A2795.jpg";
import image25 from "./images/2021_4G8A2843.jpg";
import image26 from "./images/2021_4G8A2899.jpg";
import image27 from "./images/2021_4G8A2949.jpg";
import image28 from "./images/2021_4G8A2951.jpg";
import image29 from "./images/2021_4G8A3022.jpg";
import image30 from "./images/2021_4G8A3235.jpg";
import image31 from "./images/2021_4G8A3481.jpg";
import image32 from "./images/2021_4G8A3487.jpg";
import image33 from "./images/2021_4G8A3492.jpg";
import image34 from "./images/2021_4G8A3494.jpg";
import image35 from "./images/2021_4G8A3498.jpg";
import image36 from "./images/2021_4G8A3511.jpg";
import image37 from "./images/2021_4G8A3574.jpg";
import image38 from "./images/2021_4G8A3578.jpg";
import image39 from "./images/2021_4G8A3593.jpg";

const images2021 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
  { src: image24, width: 830, height: 645 },
  { src: image25, width: 830, height: 645 },
  { src: image26, width: 830, height: 645 },
  { src: image27, width: 830, height: 645 },
  { src: image28, width: 830, height: 645 },
  { src: image29, width: 830, height: 645 },
  { src: image30, width: 830, height: 645 },
  { src: image31, width: 830, height: 645 },
  { src: image32, width: 830, height: 645 },
  { src: image33, width: 830, height: 645 },
  { src: image34, width: 830, height: 645 },
  { src: image35, width: 830, height: 645 },
  { src: image36, width: 830, height: 645 },
  { src: image37, width: 830, height: 645 },
  { src: image38, width: 830, height: 645 },
  { src: image39, width: 830, height: 645 },
];

export default images2021;
