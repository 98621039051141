import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import pdfEs from './reglamento.pdf';
import './RulesPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class RulesPage extends React.Component {
  render() {
    return (
      <div className="Rules">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Rules-hero" 
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Rules-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
APERTURA DE INSCRIPCIONES 1 AGOSTO
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Rules-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                  <h3 className="Rules-title">
                    <FormattedMessage id="rules.title" defaultMessage="Reglamento" />
                  </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Rules-info-article">
                  <h4 className="Rules-info-title">
                    <FormattedMessage id="rules.info.title" defaultMessage="Puedes descargarte el reglamento completo en esta sección" />
                  </h4>
                  <p className="Rules-info-text text-justify">
                    <FormattedMessage id="rules.info.text" defaultMessage="La empresa deportiva GLOBALDXT en colaboración con el Concello de Pontevedra, organizan el 6 de Octubre de 2024, la sexta edición de LA ROAD PONTEVEDRA “CLÁSICA EVARISTO PORTELA”, que cuenta con las debidas autorizaciones de la Federación Galega de Ciclismo y la Real Federación Española de Ciclismo." />
                  </p>
                  <p className="Rules-info-text text-justify">
                    <FormattedMessage id="rules.info.text2" defaultMessage="El objetivo es que todos los aficionados a la bicicleta de carretera puedan disfrutar de una ruta NO COMPETITIVA y superen el reto de finalizarla." />
                  </p>
                  <p className="Rules-info-text text-justify">
                    <FormattedMessage id="rules.info.text3" defaultMessage="Además, esta prueba quiere rendir homenaje a D. Evaristo Portela, que tanto ha dado al ciclismo, tanto como deportista, como en su función de director técnico." />
                  </p>
                  <div className="Rules-button-wrapper">
                    <a className="Rules-pdf-button" href={pdfEs}  rel="noopener noreferrer">Reglamento</a>
                    {/* <a className="Rules-pdf-button" href={pdfGl}  rel="noopener noreferrer">Regulamento (GL)</a> */}
                  </div>
                </article>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="Rules-info-img"
                    style={{
                      backgroundImage: `url(${Image2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center',
                      backgroundSize: 'cover',
                      height: '800px',
                      marginRight: '-15px',
                      marginLeft: '-15px'
                    }}>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default RulesPage;
