import React from 'react';
// import Header from '../_components/Header'

class GalleryPage extends React.Component {
  render() {
    return (
      <div>
        <h1>Galería</h1>
      </div>
    );
  }
}

export default GalleryPage;