import image1 from "./images/2022_2A3A6794.jpg";
import image2 from "./images/2022_2A3A6927.jpg";
import image3 from "./images/2022_2A3A6941.jpg";
import image4 from "./images/2022_2A3A6947.jpg";
import image5 from "./images/2022_2A3A6949.jpg";
import image6 from "./images/2022_2A3A6953.jpg";
import image7 from "./images/2022_2A3A6954.jpg";
import image8 from "./images/2022_2A3A6955.jpg";
import image9 from "./images/2022_2A3A6968.jpg";
import image10 from "./images/2022_2A3A6977.jpg";
import image11 from "./images/2022_2A3A7018.jpg";
import image12 from "./images/2022_2A3A7028.jpg";
import image13 from "./images/2022_2A3A7043.jpg";
import image14 from "./images/2022_2A3A7044.jpg";
import image15 from "./images/2022_2A3A7046.jpg";
import image16 from "./images/2022_2A3A7048.jpg";
import image17 from "./images/2022_2A3A7062.jpg";
import image18 from "./images/2022_2A3A7077.jpg";
import image19 from "./images/2022_2A3A7131.jpg";
import image20 from "./images/2022_2A3A7182.jpg";
import image21 from "./images/2022_2A3A7198.jpg";
import image22 from "./images/2022_2A3A7260.jpg";
import image23 from "./images/2022_2A3A7305.jpg";
import image24 from "./images/2022_2A3A7389.jpg";
import image25 from "./images/2022_2A3A7401.jpg";
import image26 from "./images/2022_2A3A7507.jpg";
import image27 from "./images/2022_2A3A7550.jpg";
import image28 from "./images/2022_2A3A7557.jpg";
import image29 from "./images/2022_2A3A7577.jpg";
import image30 from "./images/2022_2A3A7617.jpg";
import image31 from "./images/2022_7X5B6676.JPG";
import image32 from "./images/2022_7X5B6828.JPG";
import image33 from "./images/2022_7X5B7017.JPG";
import image34 from "./images/2022_7X5B7189.JPG";
import image35 from "./images/2022_7X5B7373.JPG";
import image36 from "./images/2022_7X5B7380.JPG";
import image37 from "./images/2022_7X5B7383.JPG";
import image38 from "./images/2022_7X5B7479.JPG";
import image39 from "./images/2022_7X5B7675.JPG";
import image40 from "./images/2022_2A3A7618.jpg";

const images2022 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image18, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
  { src: image24, width: 830, height: 645 },
  { src: image25, width: 830, height: 645 },
  { src: image26, width: 830, height: 645 },
  { src: image27, width: 830, height: 645 },
  { src: image28, width: 830, height: 645 },
  { src: image29, width: 830, height: 645 },
  { src: image30, width: 830, height: 645 },
  { src: image31, width: 830, height: 645 },
  { src: image32, width: 830, height: 645 },
  { src: image33, width: 830, height: 645 },
  { src: image34, width: 830, height: 645 },
  { src: image35, width: 830, height: 645 },
  { src: image36, width: 830, height: 645 },
  { src: image37, width: 830, height: 645 },
  { src: image38, width: 830, height: 645 },
  { src: image39, width: 830, height: 645 },
  { src: image40, width: 830, height: 645 },
];

export default images2022;
